import type { AppConfig } from "entities/app-config.interface";

export const dev: AppConfig = {
  PLATFORM: "dev",
  OKTA_CLIENT_ID: "0oafvec43dLPpDlQt2p7",
  OKTA_ISSUER:
    "https://freedomfinancialnetwork.okta.com/oauth2/aus3g4i87xCyMvkYC2p7",
  API_URL: "https://lendage-rate-limiter-service.dev.lndgcp.com/rls/api/v2",
  APP_VERSION: "{{DD_VERSION}}",
  DATADOG_CLIENT_TOKEN: "pubdd49c04cd4d90b88f4231f6815dbf12e",
  MASK_SENSITIVE_LOGS: false,
};

export const qa: AppConfig = {
  PLATFORM: "qa",
  OKTA_CLIENT_ID: "0oafvec43dLPpDlQt2p7",
  OKTA_ISSUER:
    "https://freedomfinancialnetwork.okta.com/oauth2/aus3g4lk5jhvAafd92p7",
  API_URL: "https://lendage-rate-limiter-service.qa.lndgcp.com/rls/api/v2",
  APP_VERSION: "{{DD_VERSION}}",
  DATADOG_CLIENT_TOKEN: "pubdd49c04cd4d90b88f4231f6815dbf12e",
  MASK_SENSITIVE_LOGS: false,
};

export const stg: AppConfig = {
  PLATFORM: "stg",
  OKTA_CLIENT_ID: "0oafvec43dLPpDlQt2p7",
  OKTA_ISSUER:
    "https://freedomfinancialnetwork.okta.com/oauth2/aus3g4lk5jhvAafd92p7",
  API_URL: "https://lendage-rate-limiter-service.stg.lndgcp.com/rls/api/v2",
  APP_VERSION: "{{DD_VERSION}}",
  DATADOG_CLIENT_TOKEN: "pubdd49c04cd4d90b88f4231f6815dbf12e",
  MASK_SENSITIVE_LOGS: true,
};

export const prd: AppConfig = {
  PLATFORM: "prd",
  OKTA_CLIENT_ID: "0oafvec43dLPpDlQt2p7",
  OKTA_ISSUER:
    "https://freedomfinancialnetwork.okta.com/oauth2/aus3g4lk5jhvAafd92p7",
  API_URL: "https://lendage-rate-limiter-service.prd.lndgcp.com/rls/api/v2",
  APP_VERSION: "{{DD_VERSION}}",
  DATADOG_CLIENT_TOKEN: "pubdd49c04cd4d90b88f4231f6815dbf12e",
  MASK_SENSITIVE_LOGS: true,
};
