import type { AxiosResponse } from "axios";
import type { Application } from "entities/application.interface";
import type {
  GetApplicationKeysResponseData,
  GetApplicationNamesResponseData,
  GetApplicationsResponseData,
} from "entities/response.interface";
import { axiosProvider } from "services/axios";

export const getApplications = (
  page: number = 0
): Promise<GetApplicationsResponseData> =>
  axiosProvider
    .get<GetApplicationsResponseData>(`/config?size=100&page=${page}`)
    .then<GetApplicationsResponseData>(
      ({ data }: AxiosResponse<GetApplicationsResponseData>) => data
    );

export const getApplicationNames = (): Promise<string[]> =>
  axiosProvider
    .get<GetApplicationNamesResponseData>("/config/applications")
    .then<string[]>(
      ({
        data: { applications },
      }: AxiosResponse<GetApplicationNamesResponseData>) => applications
    );

export const getApplicationKeys = (
  applicationName: string
): Promise<string[]> =>
  axiosProvider
    .get<GetApplicationKeysResponseData>(
      `/config/application/${applicationName}/keys`
    )
    .then<string[]>(
      ({ data: { keys } }: AxiosResponse<GetApplicationKeysResponseData>) =>
        keys
    );

export const getApplicationByName = (
  applicationName: string,
  applicationKey: string
): Promise<Application> =>
  axiosProvider
    .get<Application>(
      `/config/application/${applicationName}/key/${applicationKey}`
    )
    .then(({ data }: AxiosResponse<Application>) => data);

export const createApplication = (
  data: Omit<Application, "id">
): Promise<Application> =>
  axiosProvider
    .post<Application>("/config", data)
    .then(({ data }: AxiosResponse<Application>) => data);

export const updateApplication = (data: Application): Promise<Application> =>
  axiosProvider
    .patch<Application>(`/config/${data.id}`, data)
    .then(({ data }: AxiosResponse<Application>) => data);

export const deleteApplication = (id: Application["id"]): Promise<void> =>
  axiosProvider.delete(`/config/${id}`);
