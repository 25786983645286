import React, { FunctionComponent } from "react";
import { Alert, AlertTitle } from "@achieve/sunbeam";

import type { ErrorAlertProps } from "./ErrorAlert.interface";

export const ErrorAlert: FunctionComponent<ErrorAlertProps> = ({ error }) => {
  return (
    <Alert severity={"error"} data-testid={"error"}>
      {error.name && (
        <AlertTitle data-testid={"error-title"}>{error.name}</AlertTitle>
      )}
      {error.message}
    </Alert>
  );
};
