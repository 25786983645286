import { QueryClient } from "@sindeo/react-data-query";

import { logger } from "./logger";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: (err) => {
        logger.error(err);
      },
    },
    mutations: {
      onError: (err) => {
        logger.error(err);
      },
    },
  },
});
