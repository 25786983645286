import React, { FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  applicationFieldsConfig,
  applicationFieldsConfigList,
} from "config/applicationFieldsConfig";
import type { ApplicationCallsFormValues } from "entities/application.interface";
import type { ApplicationFieldConfigItem } from "entities/applicationFieldsConfig.interface";
import { getFieldsConfigList } from "utilities/applicationFieldsConfig";
import { renderTextField } from "utilities/form";

import { GridItem } from "components/shared/GridItem";

import type { ApplicationCallsFormProps } from "./ApplicationCallsForm.interface";

const { maxCallsPerDay, maxCallsPerHour, displayName } =
  applicationFieldsConfig;
const editPageTextFieldsConfig: ApplicationFieldConfigItem[] =
  getFieldsConfigList({
    maxCallsPerDay,
    maxCallsPerHour,
    displayName,
  });

export const ApplicationCallsForm: FunctionComponent<
  ApplicationCallsFormProps
> = ({ disabled, isEditPage }) => {
  const { control } = useFormContext<ApplicationCallsFormValues>();

  const renderTextFieldController = ({
    label,
    dataIndex,
    inputType,
    width,
    required,
  }: ApplicationFieldConfigItem) => (
    <GridItem lg={width} key={`${dataIndex}-input`}>
      <Controller
        name={dataIndex}
        control={control}
        render={renderTextField<ApplicationCallsFormValues>(dataIndex, {
          label,
          type: inputType || "text",
          disabled,
          required,
        })}
      />
    </GridItem>
  );

  return (
    <>
      {(isEditPage
        ? editPageTextFieldsConfig
        : applicationFieldsConfigList
      ).map(renderTextFieldController)}
    </>
  );
};
