import React from "react";
import type {
  ControllerProps,
  FieldErrors,
  FieldPath,
  UseFormProps,
} from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { TextField } from "@achieve/sunbeam";
import type { OutlinedTextFieldProps, TextFieldProps } from "@mui/material";
import type { ValidationProps } from "entities/validationProps.interface";
import * as yup from "yup";

export const defaultTextFieldProps: Pick<
  OutlinedTextFieldProps,
  "variant" | "fullWidth" | "size"
> = {
  variant: "outlined",
  fullWidth: true,
  size: "small",
};

export const defaultFormProps: Pick<
  UseFormProps,
  "reValidateMode" | "mode" | "criteriaMode"
> = {
  reValidateMode: "onChange",
  mode: "onChange",
  criteriaMode: "all",
};

export const DEFAULT_ERROR_MESSAGE =
  "Application not found. Please reload the page and try again";

export const getValidationProps = (
  fieldName: string,
  errors?: FieldErrors
): ValidationProps =>
  !!errors && errors[fieldName]
    ? {
        error: true,
        helperText: errors[fieldName]?.message?.toString() || undefined,
      }
    : {
        error: false,
        helperText: undefined,
      };

export const getRequiredInputMessage = (label: string): string =>
  `${label} is required`;

export const getTypeInvalidMessage = (label: string): string =>
  `Please enter a valid ${label}`;

export const getPositiveNumberMessage = (label: string): string =>
  `${label} cannot be less than 0`;

export const renderTextField =
  <T extends FieldValues>(
    fieldName: FieldPath<T>,
    props?: TextFieldProps
  ): ControllerProps<T>["render"] =>
  ({ field: { onChange, onBlur, value, name }, formState: { errors } }) =>
    (
      <TextField
        {...defaultTextFieldProps}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        {...props}
        {...getValidationProps(fieldName, errors)}
      />
    );

export const validateFieldRequired = <T,>(
  schema: yup.Schema<T | undefined>,
  label: string
): yup.Schema<NonNullable<T>> =>
  schema.required(getRequiredInputMessage(label));

export const numberValidation = (label: string) =>
  yup
    .number()
    .typeError(getTypeInvalidMessage("number"))
    .defined()
    .min(0, getPositiveNumberMessage(label));
