import React, { FunctionComponent } from "react";
import { Divider, IconButton, Tooltip } from "@achieve/sunbeam";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

import type { AddButtonProps } from "./AddButton.interface";

export const AddButton: FunctionComponent<AddButtonProps> = ({
  label,
  ...restProps
}) => {
  return (
    <Tooltip title={label} placement={"top"}>
      <Divider textAlign={"center"}>
        <IconButton {...restProps}>
          <AddCircleOutlinedIcon color={"primary"} />
        </IconButton>
      </Divider>
    </Tooltip>
  );
};
