import React, { FunctionComponent } from "react";
import { Controller, FieldErrors, useFormContext } from "react-hook-form";
import { TextField } from "@achieve/sunbeam";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import type { ApplicationScheduleFormValues } from "entities/application.interface";
import { getScheduleFieldName } from "utilities/applicationScheduleForm";
import { defaultTextFieldProps, getValidationProps } from "utilities/form";

import type { ScheduleTimeTextFieldProps } from "./ScheduleTimeTextField.interface";

export const ScheduleTimeTextField: FunctionComponent<
  ScheduleTimeTextFieldProps
> = ({ fieldName, index, ...props }) => {
  const { control } = useFormContext<ApplicationScheduleFormValues>();
  const controlFieldName = getScheduleFieldName(fieldName, index);

  return (
    <Controller<ApplicationScheduleFormValues>
      control={control}
      name={controlFieldName}
      render={({ field: { onChange, value, name }, formState: { errors } }) => (
        <DesktopTimePicker
          onChange={onChange}
          value={value}
          inputFormat={"hh:mm A"}
          renderInput={(params) => (
            <TextField
              {...defaultTextFieldProps}
              {...params}
              {...props}
              name={name}
              required={true}
              {...getValidationProps(
                fieldName,
                errors?.schedules?.[index] as FieldErrors
              )}
            />
          )}
        />
      )}
    />
  );
};
