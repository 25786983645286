import { AchieveTheme } from "@achieve/sunbeam";
import { createTheme, Theme } from "@mui/material";

const theme: Theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: AchieveTheme.palette.grey[300],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          height: 40,
        },
      },
    },
  },
});

export { theme };
