import type { RefetchPageFilters } from "@sindeo/react-data-query";
import type { ApplicationsListFilter } from "entities/application.interface";
import type { GetApplicationsResponseData } from "entities/response.interface";

export const initialFilter: ApplicationsListFilter = {
  applicationName: "",
  key: "",
};

export const refetchLastPageOptions: RefetchPageFilters<GetApplicationsResponseData> =
  {
    refetchPage: (_: any, index, allPages) => index === allPages.length - 1,
  };
