import React, { FunctionComponent, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, Grid } from "@achieve/sunbeam";
import { yupResolver } from "@hookform/resolvers/yup";
import type { ButtonProps } from "@mui/material";
import { useMutation } from "@sindeo/react-data-query";
import routes from "constants/routes";
import type {
  Application,
  ApplicationCallsFormValues,
} from "entities/application.interface";
import type { ResponseError } from "entities/response.interface";
import { useSnackbar } from "notistack";
import {
  createApplication,
  deleteApplication,
  updateApplication,
} from "services/api/ApplicationService";
import {
  applicationCallsSchema,
  getApplicationCallsFormValues,
  getApplicationCallsRequestData,
} from "utilities/applicationCallsForm";
import { DEFAULT_ERROR_MESSAGE, defaultFormProps } from "utilities/form";

import { ApplicationCallsForm } from "components/ApplicationCallsForm";
import { ApplicationCurrentCallsSection } from "components/ApplicationCurrentCallsSection";
import { ConfirmationModal } from "components/shared/ConfirmationModal";
import { GridItem } from "components/shared/GridItem";
import { LoadingButton } from "components/shared/LoadingButton";

import type {
  ApplicationCardProps,
  UpdateApplicationRequestData,
} from "./ApplicationCard.interface";

import styles from "./ApplicationCard.module.scss";

export const ApplicationCard: FunctionComponent<ApplicationCardProps> = ({
  data,
  onSubmit,
  onDelete,
  onCancel,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const methods = useForm<ApplicationCallsFormValues>({
    ...defaultFormProps,
    defaultValues: getApplicationCallsFormValues(data),
    resolver: yupResolver(applicationCallsSchema),
  });
  const { isLoading: submitLoading, mutate: mutateSubmit } = useMutation<
    Application,
    ResponseError,
    UpdateApplicationRequestData
  >({
    mutationKey: ["update-application"],
    mutationFn: (requestData: UpdateApplicationRequestData) =>
      requestData.id
        ? updateApplication({ ...requestData, id: requestData.id })
        : createApplication(requestData),
  });
  const { isLoading: deleteLoading, mutate: mutateDelete } = useMutation<
    void,
    ResponseError,
    Application["id"]
  >({
    mutationKey: ["delete-application"],
    mutationFn: deleteApplication,
  });
  const {
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = methods;
  const buttonProps: ButtonProps = {
    size: "small",
    fullWidth: true,
    disabled: submitLoading || deleteLoading,
  };
  const submitButtonDisabled = !isDirty || !isValid || deleteLoading;

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true);
  };

  const onError = useCallback(
    ({ message }: ResponseError) => {
      enqueueSnackbar(message, { variant: "error" });
    },
    [enqueueSnackbar]
  );

  const handleFormSubmit = useCallback(
    (values: ApplicationCallsFormValues) => {
      const isEditForm = !!data;
      const requestData: UpdateApplicationRequestData =
        getApplicationCallsRequestData(values, data);

      mutateSubmit(requestData, {
        onSuccess: (newApplication: Application) => {
          enqueueSnackbar(
            `Application successfully ${isEditForm ? "updated" : "created"}`,
            {
              variant: "success",
            }
          );
          if (onSubmit) {
            onSubmit();
          }
          reset(getApplicationCallsFormValues(newApplication));
        },
        onError,
      });
    },
    [data, mutateSubmit, onError, enqueueSnackbar, onSubmit, reset]
  );

  const handleDeleteConfirm = useCallback(() => {
    if (data?.id) {
      mutateDelete(data.id, {
        onSuccess: () => {
          enqueueSnackbar("Application successfully deleted", {
            variant: "success",
          });
          setDeleteModalOpen(false);
          if (onDelete) {
            onDelete();
          }
        },
        onError,
      });
    } else {
      enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: "error" });
    }
  }, [data?.id, enqueueSnackbar, onError, mutateDelete, onDelete]);

  return (
    <Card>
      <CardContent sx={{ padding: 3 }}>
        <FormProvider {...methods}>
          <form data-testid={"app-card-form"}>
            <Grid container={true} spacing={2}>
              <GridItem lg={9}>
                <Grid container={true} spacing={2}>
                  <ApplicationCallsForm disabled={submitLoading} />
                </Grid>
              </GridItem>
              <GridItem lg={3}>
                <Grid container={true} spacing={2}>
                  <GridItem md={4}>
                    <LoadingButton
                      {...buttonProps}
                      variant={"contained"}
                      loading={submitLoading}
                      disabled={submitButtonDisabled}
                      onClick={handleSubmit(handleFormSubmit)}
                      type={"submit"}
                      data-testid={"app-card-save-button"}
                    >
                      {"Save"}
                    </LoadingButton>
                  </GridItem>
                  {data ? (
                    <>
                      <GridItem md={4}>
                        <Link
                          to={`${routes.dashboardEdit}/${data.applicationName}/${data.key}`}
                          className={styles.editButtonLink}
                        >
                          <Button
                            {...buttonProps}
                            variant={"outlined"}
                            data-testid={"app-card-edit-button"}
                          >
                            {"Edit"}
                          </Button>
                        </Link>
                      </GridItem>
                      <GridItem md={4}>
                        <Button
                          {...buttonProps}
                          variant={"contained"}
                          className={styles.deleteButton}
                          data-testid={"app-card-delete-button"}
                          onClick={handleDeleteModalOpen}
                          disabled={submitLoading}
                        >
                          {"Delete"}
                        </Button>
                      </GridItem>
                    </>
                  ) : (
                    <GridItem md={4}>
                      <Button
                        {...buttonProps}
                        variant={"outlined"}
                        onClick={onCancel}
                        data-testid={"app-card-cancel-button"}
                      >
                        {"Cancel"}
                      </Button>
                    </GridItem>
                  )}
                </Grid>
              </GridItem>
              {data && <ApplicationCurrentCallsSection data={data} />}
            </Grid>
          </form>
        </FormProvider>
      </CardContent>
      <ConfirmationModal
        open={deleteModalOpen}
        title={"Delete Config?"}
        loading={deleteLoading}
        onCancel={handleDeleteModalClose}
        onConfirm={handleDeleteConfirm}
        confirmText={"Delete"}
      />
    </Card>
  );
};
