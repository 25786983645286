import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";
import routes from "constants/routes";
import appConfig from "utilities/appConfig";

const oktaConfig: OktaAuthOptions = {
  clientId: `${appConfig.OKTA_CLIENT_ID}`,
  issuer: `${appConfig.OKTA_ISSUER}`,
  redirectUri: `${window.location.origin}${routes.loginRedirect}`,
  scopes: ["openid", "profile", "email"],
  pkce: true,
};

export const oktaAuth = new OktaAuth(oktaConfig);
