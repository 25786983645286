import axios, {
  type AxiosError,
  type AxiosHeaders,
  InternalAxiosRequestConfig,
} from "axios";
import type { ResponseError } from "entities/response.interface";
import { oktaAuth } from "services/okta";
import appConfig from "utilities/appConfig";

const defaultHeaders = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
};

export const axiosProvider = axios.create({
  baseURL: `${appConfig.API_URL}`,
  headers: defaultHeaders,
  responseType: "json",
});

axiosProvider.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const accessToken = oktaAuth.getAccessToken();

    if (accessToken) {
      (config.headers as AxiosHeaders).set(
        "X-Authorization-Okta",
        `Bearer ${accessToken}`
      );
    }
    return config;
  }
);

axiosProvider.interceptors.response.use(
  (response) => response,
  ({
    name,
    message,
    response,
  }: AxiosError<{ message?: string }>): Promise<ResponseError> =>
    Promise.reject({
      name,
      message: response?.data?.message || message,
    })
);
