import React, { FunctionComponent, useEffect, useState } from "react";
import { Route, useHistory, useRouteMatch } from "react-router-dom";
import { Grid, Tab, Tabs } from "@achieve/sunbeam";
import routes from "constants/routes";

import { EditPageView } from "components/EditPageView";
import { MainPageView } from "components/MainPageView";
import { GridItem } from "components/shared/GridItem";

import {
  TabConfigItem,
  TabsConfig,
  TabsConfigList,
  TabValueEnum,
} from "./DashboardPage.interface";

const tabsConfig: TabsConfig = {
  [TabValueEnum.MAIN]: {
    label: "Main",
    children: <MainPageView />,
    path: routes.dashboard,
  },
  [TabValueEnum.EDIT]: {
    label: "Edit",
    children: <EditPageView />,
    path: [routes.dashboardEdit, routes.dashboardEditId],
  },
};

const tabsConfigList = Object.entries<TabConfigItem>(tabsConfig).map(
  ([value, restConfig]) => ({
    value,
    ...restConfig,
  })
) as TabsConfigList;

const getValue = (matchPath: string): TabValueEnum =>
  tabsConfigList.find(({ path }) =>
    Array.isArray(path) ? path.includes(matchPath) : path === matchPath
  )?.value || TabValueEnum.MAIN;

export const DashboardPage: FunctionComponent = () => {
  const { push } = useHistory();
  const { path } = useRouteMatch();
  const [activeTab, setActiveTab] = useState<TabValueEnum>(getValue(path));

  useEffect(() => {
    setActiveTab(getValue(path));
  }, [path]);

  const handleChange = (_: any, tabValue: TabValueEnum) => {
    const newPath: string | string[] = tabsConfig[tabValue].path;

    push(Array.isArray(newPath) ? newPath[0] : newPath);
    setActiveTab(tabValue);
  };

  return (
    <Grid container={true} spacing={4}>
      <GridItem>
        <Tabs value={activeTab} onChange={handleChange}>
          {tabsConfigList.map(({ value, label }) => (
            <Tab
              key={value}
              label={label}
              value={value}
              data-testid={`tab-${value.toLowerCase()}`}
            />
          ))}
        </Tabs>
      </GridItem>
      <GridItem>
        {tabsConfigList.map(({ value, children, path }) => (
          <Route
            key={`children-${value.toLowerCase()}`}
            path={path}
            exact={true}
          >
            {children}
          </Route>
        ))}
      </GridItem>
    </Grid>
  );
};
