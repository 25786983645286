import React, { FunctionComponent } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@achieve/sunbeam";

import { LoadingButton } from "components/shared/LoadingButton";

import type { ConfirmationModalProps } from "./ConfirmationModal.interface";

import styles from "./ConfirmationModal.module.scss";

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  open = false,
  title,
  message,
  onCancel,
  onConfirm,
  loading,
  confirmText,
  cancelText,
}) => {
  return (
    <Dialog
      open={open}
      fullWidth={false}
      onClose={onCancel}
      data-testid={"confirm-modal"}
    >
      <DialogTitle className={styles.title}>
        <Typography
          variant={"displayS20"}
          fontWeight={"bold"}
          data-testid={"confirm-title"}
        >
          {title}
        </Typography>
      </DialogTitle>
      {message && (
        <DialogContent dividers={false} data-testid={"confirm-message"}>
          <Typography variant={"bodyS30"}>{message}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          variant={"text"}
          onClick={onCancel}
          disabled={loading}
          fullWidth={true}
          size={"small"}
          data-testid={"confirm-cancel-button"}
        >
          {cancelText || "Cancel"}
        </Button>
        <LoadingButton
          type={"submit"}
          variant={"contained"}
          loading={loading}
          onClick={onConfirm}
          fullWidth={true}
          size={"small"}
          data-testid={"confirm-submit-button"}
        >
          {confirmText || "Confirm"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
