import React, { FunctionComponent, ReactNode } from "react";
import { Backdrop, CircularProgress } from "@achieve/sunbeam";
import classNames from "classnames";

import { SpinnerProps } from "./Spinner.interface";

import styles from "./Spinner.module.scss";

export const Spinner: FunctionComponent<SpinnerProps> = ({
  loading = false,
  fullWidth = false,
  className = "",
  children,
}) => {
  const spinnerContent: ReactNode = loading && (
    <Backdrop
      open={true}
      invisible={!fullWidth}
      className={classNames([
        styles.backdrop,
        fullWidth ? styles.fullWidth : styles.inner,
        className,
      ])}
    >
      <CircularProgress color={"primary"} />
    </Backdrop>
  );

  return fullWidth ? (
    <>{spinnerContent}</>
  ) : (
    <div className={styles.contentWrapper}>
      {spinnerContent}
      {children}
    </div>
  );
};
