import React, { FunctionComponent } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  AchieveTheme,
  StyledEngineProvider,
  SunbeamThemeProvider,
} from "@achieve/sunbeam";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { theme } from "mui/theme";
import { SnackbarProvider } from "notistack";
import { oktaAuth } from "services/okta";

import { Navigation } from "components/layout/Navigation";

const restoreOriginalUri = (_: any, originalUri: string) => {
  window.location.replace(
    toRelativeUrl(originalUri || "/", window.location.origin)
  );
};

export const App: FunctionComponent = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledEngineProvider injectFirst={true}>
        <SunbeamThemeProvider customThemes={[theme, AchieveTheme]}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Security
              oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
            >
              <Router>
                <Navigation />
              </Router>
            </Security>
          </SnackbarProvider>
        </SunbeamThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};
