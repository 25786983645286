import React, { FunctionComponent } from "react";
import { Divider, Typography } from "@achieve/sunbeam";

import { GridItem } from "components/shared/GridItem";

import type { ApplicationCurrentCallsSectionProps } from "./ApplicationCurrentCallsSection.interface";

import styles from "./ApplicationCurrentCallsSection.module.scss";

export const ApplicationCurrentCallsSection: FunctionComponent<
  ApplicationCurrentCallsSectionProps
> = ({ data: { currentCallsPerDay, currentCallsPerHour } }) => {
  return (
    <GridItem>
      <Typography variant={"bodyS30"} className={styles.wrapper}>
        <span>{`Current Calls per Day: ${currentCallsPerDay || 0}`}</span>
        <Divider
          sx={{
            borderColor: "inherit",
            marginX: 1,
            marginY: 0.5,
          }}
          orientation={"vertical"}
          flexItem
        />
        <span>{`Current Calls per Hour: ${currentCallsPerHour || 0}`}</span>
      </Typography>
    </GridItem>
  );
};
