import { dev, prd, qa, stg } from "config/appConfig";
import { AppConfig } from "entities/app-config.interface";
import { Platform } from "entities/platform.type";

const configMap: Record<Platform, AppConfig> = {
  dev,
  qa,
  stg,
  prd,
};

export function getCurrentPlatform(): Platform {
  const { hostname } = window.location;

  if (hostname.includes(".prd.") || hostname.includes("lendage.com")) {
    return "prd";
  }

  if (hostname.includes(".stg.")) {
    return "stg";
  }

  if (hostname.includes(".qa.")) {
    return "qa";
  }

  return "dev";
}

const platform = getCurrentPlatform();
const config: AppConfig = configMap[platform];

export default config;
