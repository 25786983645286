import { applicationFieldsConfig } from "config/applicationFieldsConfig";
import type {
  ApplicationFieldConfigItem,
  ApplicationFieldConfigValue,
  ApplicationFieldsConfig,
} from "entities/applicationFieldsConfig.interface";

export const getFieldsConfigList = (
  config: Partial<ApplicationFieldsConfig> = applicationFieldsConfig
): ApplicationFieldConfigItem[] =>
  Object.entries(config).map(
    ([dataIndex, config]: [string, ApplicationFieldConfigValue]) => ({
      dataIndex,
      ...config,
    })
  ) as ApplicationFieldConfigItem[];
