import React, { FunctionComponent, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Grid, MenuItem, Switch } from "@achieve/sunbeam";
import { weekDays } from "config/dateConfig";
import type { ApplicationScheduleFormValues } from "entities/application.interface";
import { getScheduleFieldName } from "utilities/applicationScheduleForm";
import { renderTextField } from "utilities/form";

import { ScheduleTimeTextField } from "components/ScheduleTimeTextField";
import { GridItem } from "components/shared/GridItem";

import type { ApplicationScheduleFormProps } from "./ApplicationScheduleForm.interface";

export const ApplicationScheduleForm: FunctionComponent<
  ApplicationScheduleFormProps
> = ({ index }) => {
  const { control, trigger } = useFormContext<ApplicationScheduleFormValues>();
  const weekDayFieldName = getScheduleFieldName("dayOfWeek", index);
  const { schedules } = useWatch({ control });
  const { startTime, endTime } = schedules?.[index] || {};

  useEffect(() => {
    if (startTime && endTime) {
      trigger();
    }
  }, [trigger, startTime, endTime]);

  return (
    <>
      <GridItem lg={1}>
        <Controller<ApplicationScheduleFormValues>
          control={control}
          name={getScheduleFieldName("enabled", index)}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Switch
              size={"medium"}
              onChange={onChange}
              onBlur={onBlur}
              checked={!!value}
              name={name}
            />
          )}
        />
        <Controller
          render={() => <></>}
          control={control}
          name={getScheduleFieldName("id", index)}
        />
      </GridItem>
      <GridItem lg={9}>
        <Grid container={true} spacing={4}>
          <GridItem lg={4}>
            <Controller<ApplicationScheduleFormValues>
              control={control}
              name={weekDayFieldName}
              render={renderTextField<ApplicationScheduleFormValues>(
                weekDayFieldName,
                {
                  label: "Week Day",
                  select: true,
                  children: weekDays.map((value: string) => (
                    <MenuItem key={value} value={value.toUpperCase()}>
                      {value}
                    </MenuItem>
                  )),
                }
              )}
            />
          </GridItem>
          <GridItem lg={4}>
            <ScheduleTimeTextField
              label={"Start Time"}
              fieldName={"startTime"}
              index={index}
            />
          </GridItem>
          <GridItem lg={4}>
            <ScheduleTimeTextField
              label={"End Time"}
              fieldName={"endTime"}
              index={index}
            />
          </GridItem>
        </Grid>
      </GridItem>
    </>
  );
};
