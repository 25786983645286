import type { FieldPath } from "react-hook-form";
import type {
  ApplicationScheduleFormValues,
  ScheduleSlotFormValues,
} from "entities/application.interface";

export const getScheduleFieldName = (
  name: keyof ScheduleSlotFormValues,
  index: number
): FieldPath<ApplicationScheduleFormValues> => `schedules.${index}.${name}`;
