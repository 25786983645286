import React, { FunctionComponent, useEffect, useState } from "react";
import { CircularProgress, Grid } from "@achieve/sunbeam";
import type { ApplicationsListFilter } from "entities/application.interface";
import { useGetApplicationsQuery } from "hooks/useGetApplicationsQuery";

import { ApplicationCard } from "components/ApplicationCard";
import { ApplicationsList } from "components/ApplicationsList";
import { ApplicationsListFilterBar } from "components/ApplicationsListFilterBar";
import { AddButton } from "components/shared/AddButton";
import { ErrorAlert } from "components/shared/ErrorAlert";
import { GridItem } from "components/shared/GridItem";
import { Spinner } from "components/shared/Spinner";

import { initialFilter, refetchLastPageOptions } from "./MainPageView.utils";

export const MainPageView: FunctionComponent = () => {
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isInitialLoading,
    isRefetching,
    error,
    refetch,
  } = useGetApplicationsQuery();
  const [filter, setFilter] = useState<ApplicationsListFilter>(initialFilter);
  const [newAppsList, setNewAppsList] = useState<number[]>([]);
  const isLoading = isInitialLoading || isRefetching;

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage]);

  const handleFilterChange = (newFilter: Partial<ApplicationsListFilter>) => {
    setFilter((prevState) => ({ ...prevState, ...newFilter }));
  };

  const handleFilterReset = () => {
    setFilter(initialFilter);
  };

  const handleAddClick = () => {
    setNewAppsList((prevState: number[]) => [
      ...prevState,
      prevState.length + 1,
    ]);
  };

  const removeRow = (index: number) => {
    setNewAppsList((prevState: number[]) => {
      const result = [...prevState];

      result.splice(index, 1);
      return result;
    });
  };

  const handleRowSubmit = (index: number) => () => {
    refetch(refetchLastPageOptions);
    removeRow(index);
  };

  return (
    <Grid container={true} spacing={4}>
      <GridItem>
        <ApplicationsListFilterBar
          value={filter}
          onChange={handleFilterChange}
          onReset={handleFilterReset}
        />
      </GridItem>
      {newAppsList.map((id: number, index: number) => {
        const key = `new-app-form-${id}`;

        return (
          <GridItem key={key} data-testid={key}>
            <ApplicationCard
              onCancel={() => removeRow(index)}
              onSubmit={handleRowSubmit(index)}
            />
          </GridItem>
        );
      })}
      <GridItem>
        <AddButton
          onClick={handleAddClick}
          label={"Add New Application"}
          data-testid={"add-app-button"}
        />
      </GridItem>
      {error && (
        <GridItem data-testid={"app-list-error"}>
          <ErrorAlert error={error} />
        </GridItem>
      )}
      <GridItem>
        <Spinner loading={isLoading}>
          {!isInitialLoading && !error && <ApplicationsList filter={filter} />}
        </Spinner>
      </GridItem>
      {isFetchingNextPage && (
        <GridItem sx={{ textAlign: "center" }}>
          <CircularProgress color={"primary"} />
        </GridItem>
      )}
    </Grid>
  );
};
