import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@achieve/sunbeam";
import { LoginCallback, SecureRoute } from "@okta/okta-react";
import { QueryClientProvider } from "@sindeo/react-data-query";
import routes from "constants/routes";
import { queryClient } from "services/reactQuery";

import { DashboardPage } from "components/DashboardPage";
import { Logo } from "components/layout/Logo";
import { ErrorAlert } from "components/shared/ErrorAlert";
import { GridItem } from "components/shared/GridItem";

export const Navigation: FunctionComponent = () => {
  return (
    <Container maxWidth={"xl"} sx={{ paddingY: 4 }}>
      <Grid container={true} spacing={4}>
        <GridItem>
          <Logo />
        </GridItem>
        <GridItem>
          <Switch>
            <Route path={routes.loginRedirect} exact={true}>
              <LoginCallback
                errorComponent={ErrorAlert}
                loadingElement={
                  <Backdrop open={true} invisible={true}>
                    <CircularProgress color={"primary"} />
                  </Backdrop>
                }
              />
            </Route>
            <SecureRoute
              path={[
                routes.dashboard,
                routes.dashboardEdit,
                routes.dashboardEditId,
              ]}
              exact={true}
            >
              <QueryClientProvider client={queryClient}>
                <DashboardPage />
              </QueryClientProvider>
            </SecureRoute>
            <Route path={"*"}>
              <Typography component={"h2"} align={"center"}>
                {"Page Not Found"}
              </Typography>
            </Route>
          </Switch>
        </GridItem>
      </Grid>
    </Container>
  );
};
