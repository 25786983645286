import type {
  ApplicationFieldConfigItem,
  ApplicationFieldsConfig,
} from "entities/applicationFieldsConfig.interface";
import { getFieldsConfigList } from "utilities/applicationFieldsConfig";

export const applicationFieldsConfig: ApplicationFieldsConfig = {
  applicationName: {
    label: "Application",
    width: 3,
    required: true,
  },
  key: {
    label: "Key",
    width: 3,
    required: true,
  },
  maxCallsPerDay: {
    label: "Max Calls per Day",
    inputType: "number",
    width: 3,
    required: true,
  },
  maxCallsPerHour: {
    label: "Max Calls per Hour",
    inputType: "number",
    width: 3,
    required: true,
  },
  displayName: {
    label: "Description",
    width: 12,
    headerHidden: true,
  },
};

export const applicationFieldsConfigList: ApplicationFieldConfigItem[] =
  getFieldsConfigList();
