import type {
  Application,
  ApplicationCallsFormValues,
} from "entities/application.interface";
import { numberValidation, validateFieldRequired } from "utilities/form";
import * as yup from "yup";

export const applicationCallsSchema: yup.ObjectSchema<ApplicationCallsFormValues> =
  yup.object({
    applicationName: validateFieldRequired<string>(
      yup.string(),
      "Application name"
    ),
    key: validateFieldRequired<string>(yup.string(), "Key name"),
    displayName: yup.string(),
    maxCallsPerDay: numberValidation("Calls per day"),
    maxCallsPerHour: numberValidation("Calls per hour"),
  });

export const getApplicationCallsFormValues = (
  data?: Application
): ApplicationCallsFormValues => {
  const {
    applicationName = "",
    key = "",
    displayName = "",
    maxCallsPerDay = "",
    maxCallsPerHour = "",
  } = data || {};

  return {
    applicationName,
    key,
    displayName,
    maxCallsPerDay,
    maxCallsPerHour,
  };
};

export const getApplicationCallsRequestData = (
  formValues: ApplicationCallsFormValues,
  data?: Application
): Omit<Application, "id"> => ({
  ...data,
  ...formValues,
  schedules: data?.schedules || [],
});
