import type { RefetchPageFilters } from "@sindeo/react-data-query";
import type {
  Application,
  ApplicationsListFilter,
} from "entities/application.interface";
import type { GetApplicationsResponseData } from "entities/response.interface";

import type { ApplicationView } from "./ApplicationsList.interface";

const stringIncluded = (value: string, filter: string): boolean =>
  !filter || value.toLowerCase().includes(filter.toLowerCase());

export const getFilteredList = (
  list: ApplicationView[],
  { applicationName: filterAppName, key: filterKeyName }: ApplicationsListFilter
): ApplicationView[] =>
  filterAppName || filterKeyName
    ? list.filter(
        ({ applicationName, key }: Application) =>
          stringIncluded(applicationName, filterAppName) &&
          stringIncluded(key, filterKeyName)
      )
    : list;

export const getCardKey = ({ applicationName, key }: Application): string =>
  `${applicationName}-${key}`;

export const getFormattedList = (
  allPages: GetApplicationsResponseData[] = []
): ApplicationView[] =>
  allPages
    .map(({ configs = [], currentPage }: GetApplicationsResponseData) =>
      configs.map((item: Application) => ({
        ...item,
        pageIndex: currentPage,
      }))
    )
    .flat();

export const refetchCurrAndNextPageOptions = (
  pageIndex: number
): RefetchPageFilters<GetApplicationsResponseData> => ({
  refetchPage: (_: any, index) => index >= pageIndex,
});
