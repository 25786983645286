import React, { ChangeEvent, FunctionComponent, useCallback } from "react";
import { Button, Grid, TextField } from "@achieve/sunbeam";
import { useGetApplicationsQuery } from "hooks/useGetApplicationsQuery";
import { defaultTextFieldProps } from "utilities/form";

import { GridItem } from "components/shared/GridItem";

import type { ApplicationsListFilterBarProps } from "./ApplicationsListFilterBar.interface";

export const ApplicationsListFilterBar: FunctionComponent<
  ApplicationsListFilterBarProps
> = ({ value, onChange, onReset }) => {
  const { isLoading, isFetchingNextPage } = useGetApplicationsQuery();
  const { applicationName, key } = value;
  const clearButtonVisible = applicationName || key;

  const handleChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange({ [target.name]: target.value });
    },
    [onChange]
  );

  const textFieldProps = {
    ...defaultTextFieldProps,
    disabled: isLoading || isFetchingNextPage,
    onChange: handleChange,
  };

  return (
    <form role={"search"}>
      <Grid container={true} spacing={4}>
        <GridItem lg={3}>
          <TextField
            {...textFieldProps}
            label={"Filter by Application Name"}
            value={applicationName}
            name={"applicationName"}
            type={"search"}
            inputProps={{
              "data-testid": "filter-app-name-input",
            }}
          />
        </GridItem>
        <GridItem lg={3}>
          <TextField
            {...textFieldProps}
            label={"Filter by Key Name"}
            value={key}
            name={"key"}
            type={"search"}
            inputProps={{
              "data-testid": "filter-key-name-input",
            }}
          />
        </GridItem>
        {clearButtonVisible && (
          <GridItem lg={2}>
            <Button
              variant={"outlined"}
              color={"primary"}
              size={"small"}
              onClick={onReset}
              fullWidth={true}
              data-testid={"filter-reset-button"}
            >
              {"Clear filters"}
            </Button>
          </GridItem>
        )}
      </Grid>
    </form>
  );
};
