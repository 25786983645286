import React, { FunctionComponent } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Card, CardContent, Grid, Typography } from "@achieve/sunbeam";
import type { ApplicationScheduleFormValues } from "entities/application.interface";

import { ApplicationScheduleForm } from "components/ApplicationScheduleForm";
import { AddButton } from "components/shared/AddButton";
import { GridItem } from "components/shared/GridItem";

import type { ApplicationScheduleSectionProps } from "./ApplicationScheduleSection.interface";

import styles from "./ApplicationScheduleSection.module.scss";

export const ApplicationScheduleSection: FunctionComponent<
  ApplicationScheduleSectionProps
> = ({ loading }) => {
  const { control } = useFormContext<ApplicationScheduleFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "schedules",
  });

  const handleAddClick = () => {
    append({
      dayOfWeek: "",
      startTime: null,
      endTime: null,
      id: "",
      enabled: false,
    });
  };

  const handleRemoveClick = (index: number) => () => {
    remove(index);
  };

  return (
    <>
      <GridItem>
        <Typography component={"h2"} variant={"displayS30"}>
          {"Schedule"}
        </Typography>
      </GridItem>
      {!loading && (
        <>
          {fields.map((item, index) => (
            <GridItem key={item.id}>
              <Card data-testid={`schedule-card-${index}`}>
                <CardContent sx={{ padding: 3 }}>
                  <Grid container={true} spacing={4}>
                    <ApplicationScheduleForm index={index} />
                    <GridItem lg={2}>
                      <Button
                        size={"small"}
                        fullWidth={true}
                        variant={"contained"}
                        className={styles.deleteButton}
                        onClick={handleRemoveClick(index)}
                        data-testid={"delete-schedule-button"}
                      >
                        {"Delete"}
                      </Button>
                    </GridItem>
                  </Grid>
                </CardContent>
              </Card>
            </GridItem>
          ))}
          {!fields.length && (
            <GridItem>
              <Typography component={"h2"} align={"center"}>
                {"No schedules yet"}
              </Typography>
            </GridItem>
          )}
        </>
      )}
      <GridItem>
        <AddButton
          onClick={handleAddClick}
          label={"Add New Schedule"}
          data-testid={"add-schedule-button"}
        />
      </GridItem>
    </>
  );
};
