import React, {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useHistory } from "react-router";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from "@achieve/sunbeam";
import type { TextFieldProps } from "@mui/material";
import { useQueries } from "@sindeo/react-data-query";
import { applicationFieldsConfig } from "config/applicationFieldsConfig";
import routes from "constants/routes";
import type { ApplicationCallsFormValues } from "entities/application.interface";
import type { ResponseError } from "entities/response.interface";
import {
  getApplicationKeys,
  getApplicationNames,
} from "services/api/ApplicationService";
import { defaultTextFieldProps, getValidationProps } from "utilities/form";

import { ErrorAlert } from "components/shared/ErrorAlert";
import { GridItem } from "components/shared/GridItem";

import type { ApplicationSelectFormProps } from "./ApplicationSelectForm.interface";

const getOptions = (values: string[] = []): ReactNode[] =>
  values.map((value: string) => (
    <MenuItem value={value} key={value} data-testid={`${value}-option`}>
      {value}
    </MenuItem>
  ));

const { applicationName: appNameFieldConfig, key: keyNameFieldConfig } =
  applicationFieldsConfig;

export const ApplicationSelectForm: FunctionComponent<
  ApplicationSelectFormProps
> = (props) => {
  const { replace } = useHistory();
  const { control, setValue } = useFormContext<ApplicationCallsFormValues>();
  const { applicationName = "", key = "" } =
    useWatch<ApplicationCallsFormValues>();
  const [
    { data: appNames, isLoading: appNamesLoading, error: appNamesError },
    {
      data: appKeys,
      isInitialLoading: appKeysInitialLoading,
      isRefetching: appKeysRefetchLoading,
      error: appKeysError,
    },
  ] = useQueries({
    queries: [
      {
        queryKey: ["get-application-names"],
        queryFn: getApplicationNames,
      },
      {
        queryKey: ["get-application-keys", applicationName],
        queryFn: () => getApplicationKeys(applicationName),
        enabled: !!applicationName,
      },
    ],
  });
  const error = (appNamesError || appKeysError) as ResponseError | undefined;

  useEffect(() => {
    if (applicationName && key) {
      replace(`${routes.dashboardEdit}/${applicationName}/${key}`);
    }
  }, [applicationName, key, replace]);

  useEffect(() => {
    if (appKeys?.length === 1) {
      setValue("key", appKeys[0]);
    }
  }, [appKeys, setValue]);

  const getTextFieldProps = useCallback(
    (isLoading: boolean, disabled?: boolean): TextFieldProps => ({
      ...defaultTextFieldProps,
      ...props,
      disabled: isLoading || props.disabled || disabled,
      select: !isLoading,
      InputProps: {
        endAdornment: isLoading && (
          <InputAdornment position={"end"}>
            <CircularProgress color={"inherit"} size={20} />
          </InputAdornment>
        ),
      },
    }),
    [props]
  );

  const handleAppNameChange = useCallback(
    (onChange: ControllerRenderProps["onChange"]) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        setValue("key", "");
        replace(routes.dashboardEdit);
        return onChange(e);
      },
    [setValue, replace]
  );

  return error ? (
    <GridItem data-testid={"select-inputs-error"}>
      <ErrorAlert error={error} />
    </GridItem>
  ) : (
    <>
      <GridItem lg={appNameFieldConfig.width}>
        <Controller<ApplicationCallsFormValues>
          name={"applicationName"}
          control={control}
          render={({
            field: { onChange, onBlur, value, name },
            formState: { errors },
          }) => (
            <TextField
              {...getTextFieldProps(appNamesLoading)}
              onChange={handleAppNameChange(onChange)}
              onBlur={onBlur}
              value={value}
              name={name}
              required={true}
              label={appNameFieldConfig.label}
              {...getValidationProps("applicationName", errors)}
              inputProps={{
                "data-testid": "app-name-select-input",
              }}
            >
              {getOptions(appNames)}
            </TextField>
          )}
        />
      </GridItem>
      <GridItem lg={keyNameFieldConfig.width}>
        <Controller<ApplicationCallsFormValues>
          name={"key"}
          control={control}
          render={({
            field: { onChange, onBlur, value, name },
            formState: { errors },
          }) => (
            <TextField
              {...getTextFieldProps(
                appKeysInitialLoading || appKeysRefetchLoading,
                !applicationName
              )}
              required={true}
              onBlur={onBlur}
              value={value}
              name={name}
              onChange={onChange}
              label={keyNameFieldConfig.label}
              {...getValidationProps("key", errors)}
              inputProps={{
                "data-testid": "app-key-select-input",
              }}
            >
              {getOptions(appKeys)}
            </TextField>
          )}
        />
      </GridItem>
    </>
  );
};
