import React, { FunctionComponent } from "react";
import { LoadingButton as SunbeamLoadingButton } from "@achieve/sunbeam";

import type { LoadingButtonProps } from "./LoadingButton.interface";

export const LoadingButton: FunctionComponent<LoadingButtonProps> = (props) => {
  return (
    <SunbeamLoadingButton
      {...props}
      sx={props.loading ? { pointerEvents: "none" } : undefined}
    >
      {props.children}
    </SunbeamLoadingButton>
  );
};
