import { useInfiniteQuery } from "@sindeo/react-data-query";
import type {
  GetApplicationsResponseData,
  ResponseError,
} from "entities/response.interface";
import { getApplications } from "services/api/ApplicationService";

export const useGetApplicationsQuery = () =>
  useInfiniteQuery<GetApplicationsResponseData, ResponseError>({
    queryKey: ["get-applications"],
    queryFn: ({ pageParam = 0 }) => getApplications(pageParam),
    getNextPageParam: ({
      totalPages,
      currentPage,
    }: GetApplicationsResponseData) => {
      const nextPage = currentPage + 1;

      return nextPage < totalPages ? nextPage : undefined;
    },
  });
