import { ReactNode } from "react";

export enum TabValueEnum {
  MAIN = "main",
  EDIT = "edit",
}

export interface TabConfigItem {
  label: string;
  children: ReactNode;
  path: string | string[];
}

export type TabsConfig = Record<TabValueEnum, TabConfigItem>;
export type TabsConfigList = Array<{ value: TabValueEnum } & TabConfigItem>;
