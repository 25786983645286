import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Grid, Typography } from "@achieve/sunbeam";
import { useGetApplicationsQuery } from "hooks/useGetApplicationsQuery";

import { ApplicationCard } from "components/ApplicationCard";
import { GridItem } from "components/shared/GridItem";

import type {
  ApplicationsListProps,
  ApplicationView,
} from "./ApplicationsList.interface";
import {
  getCardKey,
  getFilteredList,
  getFormattedList,
  refetchCurrAndNextPageOptions,
} from "./ApplicationsList.utils";

export const ApplicationsList: FunctionComponent<ApplicationsListProps> = ({
  filter,
}) => {
  const { data, refetch } = useGetApplicationsQuery();
  const [filteredList, setFilteredList] = useState<ApplicationView[]>([]);
  const formattedList = useMemo(
    () => getFormattedList(data?.pages),
    [data?.pages]
  );

  useEffect(() => {
    setFilteredList(getFilteredList(formattedList, filter));
  }, [formattedList, filter]);

  const handleDelete = useCallback(
    (pageIndex: number) => () => {
      refetch(refetchCurrAndNextPageOptions(pageIndex));
    },
    [refetch]
  );

  return (
    <Grid container={true} spacing={4}>
      {filteredList.map((item: ApplicationView) => {
        const key = getCardKey(item);

        return (
          <GridItem key={key} data-testid={key}>
            <ApplicationCard
              data={item}
              onDelete={handleDelete(item.pageIndex)}
            />
          </GridItem>
        );
      })}
      {!filteredList.length && (
        <GridItem>
          <Typography
            align={"center"}
            component={"p"}
            data-testid={"no-data-label"}
          >
            {"No applications available"}
          </Typography>
        </GridItem>
      )}
    </Grid>
  );
};
