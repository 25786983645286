import React, { FunctionComponent } from "react";
import { AchieveLogoIcon } from "@sindeo/react-components";

export const Logo: FunctionComponent = () => {
  return (
    <a
      href={"https://www.achieve.com"}
      target={"_blank"}
      rel="noreferrer"
      data-testid={"logo-link"}
    >
      <AchieveLogoIcon data-testid="logo-img" />
    </a>
  );
};
