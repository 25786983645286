import React, { FunctionComponent, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid } from "@achieve/sunbeam";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@sindeo/react-data-query";
import routes from "constants/routes";
import type {
  Application,
  ApplicationScheduleFormValues,
} from "entities/application.interface";
import type { ResponseError } from "entities/response.interface";
import { useSnackbar } from "notistack";
import {
  getApplicationByName,
  updateApplication,
} from "services/api/ApplicationService";
import { DEFAULT_ERROR_MESSAGE, defaultFormProps } from "utilities/form";

import { ApplicationCallsForm } from "components/ApplicationCallsForm";
import { ApplicationCurrentCallsSection } from "components/ApplicationCurrentCallsSection";
import { ApplicationScheduleSection } from "components/ApplicationScheduleSection";
import { ApplicationSelectForm } from "components/ApplicationSelectForm";
import { ErrorAlert } from "components/shared/ErrorAlert";
import { GridItem } from "components/shared/GridItem";
import { Spinner } from "components/shared/Spinner";

import {
  applicationScheduleSchema,
  getApplicationScheduleData,
  getApplicationScheduleFormValues,
} from "./EditPageView.utils";

const defaultValues: ApplicationScheduleFormValues =
  getApplicationScheduleFormValues();

export const EditPageView: FunctionComponent = () => {
  const { applicationName = "", applicationKey = "" } = useParams<{
    applicationName: string;
    applicationKey: string;
  }>();
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const appSelected = !!(applicationName && applicationKey);
  const { data, isInitialLoading, isRefetching, error } = useQuery<
    Application,
    ResponseError
  >({
    queryKey: ["get-application-by-name", applicationName, applicationKey],
    queryFn: () => getApplicationByName(applicationName, applicationKey),
    enabled: appSelected,
  });
  const { isLoading: submitLoading, mutate } = useMutation<
    Application,
    ResponseError,
    Application
  >({
    mutationKey: ["update-application"],
    mutationFn: (requestData: Application) => updateApplication(requestData),
  });
  const methods = useForm<ApplicationScheduleFormValues>({
    ...defaultFormProps,
    defaultValues,
    resolver: yupResolver(applicationScheduleSchema),
  });
  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;
  const isLoading = isInitialLoading || isRefetching || submitLoading;
  const submitButtonDisabled = !isDirty || !isValid || isLoading;

  useEffect(() => {
    if (data) {
      reset(getApplicationScheduleFormValues(data));
    }
  }, [data, reset]);

  const handleCancel = () => {
    push(routes.dashboard);
  };

  const handleFormSubmit = useCallback(
    (values: ApplicationScheduleFormValues) => {
      if (data?.id) {
        const requestData: Application = getApplicationScheduleData(
          values,
          data
        );

        mutate(requestData, {
          onSuccess: (newData: Application) => {
            enqueueSnackbar("Application successfully updated", {
              variant: "success",
            });
            reset(getApplicationScheduleFormValues(newData));
          },
          onError: ({ message }: ResponseError) => {
            enqueueSnackbar(message, { variant: "error" });
          },
        });
      } else {
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: "error" });
      }
    },
    [data, enqueueSnackbar, mutate, reset]
  );

  return (
    <form data-testid={"edit-page-form"}>
      <FormProvider {...methods}>
        <Grid container={true} spacing={4}>
          {error ? (
            <GridItem data-testid={"edit-page-error"}>
              <ErrorAlert error={error} />
            </GridItem>
          ) : (
            <>
              <GridItem>
                <Spinner loading={isLoading}>
                  <Grid container={true} spacing={4}>
                    <ApplicationSelectForm disabled={isLoading} />
                    {appSelected && (
                      <>
                        <ApplicationCallsForm
                          disabled={isLoading}
                          isEditPage={true}
                        />
                        {data && <ApplicationCurrentCallsSection data={data} />}
                        <ApplicationScheduleSection
                          loading={isInitialLoading}
                        />
                      </>
                    )}
                  </Grid>
                </Spinner>
              </GridItem>
              {appSelected && (
                <GridItem>
                  <Grid container={true} spacing={4} justifyContent={"end"}>
                    <GridItem lg={"auto"}>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        onClick={handleCancel}
                        data-testid={"edit-page-cancel"}
                      >
                        {"Cancel"}
                      </Button>
                    </GridItem>
                    <GridItem lg={"auto"}>
                      <Button
                        data-testid={"edit-page-submit"}
                        size={"small"}
                        disabled={submitButtonDisabled}
                        onClick={handleSubmit(handleFormSubmit)}
                      >
                        {"Save Changes"}
                      </Button>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
            </>
          )}
        </Grid>
      </FormProvider>
    </form>
  );
};
