import type {
  Application,
  ApplicationScheduleFormValues,
  ScheduleSlotData,
  ScheduleSlotFormValues,
} from "entities/application.interface";
import moment from "moment";
import {
  applicationCallsSchema,
  getApplicationCallsFormValues,
  getApplicationCallsRequestData,
} from "utilities/applicationCallsForm";
import {
  getRequiredInputMessage,
  getTypeInvalidMessage,
  validateFieldRequired,
} from "utilities/form";
import * as yup from "yup";

const isDateValid = (date: any): boolean =>
  date instanceof Date && !isNaN(date.valueOf());

export const applicationScheduleSchema: yup.ObjectSchema<ApplicationScheduleFormValues> =
  applicationCallsSchema.shape({
    schedules: yup
      .array()
      .defined()
      .of(
        yup.object({
          id: yup.string(),
          enabled: yup.boolean(),
          dayOfWeek: validateFieldRequired<string>(yup.string(), "Week day"),
          startTime: yup
            .date()
            .nullable()
            .required(getRequiredInputMessage("Start time"))
            .typeError(getTypeInvalidMessage("time")),
          endTime: yup
            .date()
            .nullable()
            .required(getRequiredInputMessage("End time"))
            .typeError(getTypeInvalidMessage("time"))
            .when("startTime", ([startTime]: any[], value: any) => {
              return isDateValid(startTime)
                ? value.min(startTime, "End time cannot be before start time")
                : value;
            }),
        })
      ),
  });

const TIME_FORMAT = "hh:mm A";

const getDateFormValue = (time: string = ""): Date | null =>
  time ? moment(time, [TIME_FORMAT]).toDate() : null;

const getTimeString = (date: Date | null): string =>
  date ? moment(date).format(TIME_FORMAT) : "";

export const getApplicationScheduleFormValues = (
  data?: Application
): ApplicationScheduleFormValues => ({
  ...getApplicationCallsFormValues(data),
  schedules: (data?.schedules || []).map((item: ScheduleSlotData) => ({
    ...item,
    startTime: getDateFormValue(item.startTime),
    endTime: getDateFormValue(item.endTime),
  })),
});

export const getApplicationScheduleData = (
  formValues: ApplicationScheduleFormValues,
  data: Application
): Application => ({
  id: data.id,
  ...getApplicationCallsRequestData(formValues, data),
  schedules: formValues.schedules.map((item: ScheduleSlotFormValues) => ({
    ...item,
    startTime: getTimeString(item.startTime),
    endTime: getTimeString(item.endTime),
  })),
});
